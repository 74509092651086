import YoutubeBackground from 'react-youtube-background';
import './App.css';

function App() {
  return (
		<YoutubeBackground
  videoId="yWomJLlkONc"
	className="video-bg"
	overlay="rgba(255, 255, 255, 0.8)"
	// overlay="rgba(9, 102, 194, 0.75)"
>
	<div className="flex items-center justify-center text-black flex-col w-full md:w-10/12 m-auto text-center bg-opacity-75 p-6 rounded-2xl">

	<h1 className="text-5xl mb-6 font-bold">Hello! 👋</h1>
	<h4 className="text-2xl mb-6">I use this page to send and receive emails so I can have my <a className="text-yellow-500" href="https://docs.sendgrid.com/ui/sending-email/dmarc" target="_blank">DMARC</a>, <a className="text-yellow-500" href="https://docs.sendgrid.com/ui/account-and-settings/spf-records" target="_blank">SPF</a>, and <a className="text-yellow-500" href="https://docs.sendgrid.com/ui/account-and-settings/dkim-records" target="_blank">DKIM</a> records set up the right way and so you and I can talk without messages going to the wrong inbox.</h4>
	<h4 className="w-full md:w-2/3 lg: w-1/2">If you have any questions about our company, please feel free to email me back. 
	<br/>We’re friendly and happy to provide value if we can.</h4>
	</div>
</YoutubeBackground>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
